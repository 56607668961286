<template>

    <div class="p-4 py-6" v-if="multiLang" style="min-height: 15rem">
    
        <div 
        class="flex justify-between mt-1">
            <label for="">{{ isoLanguages[multiLang.lang]?.name }}</label>
            <div class="actions flex items-center gap-x-3">
                <a 
                    href="#" 
                    class="text-sm hover:text-black"
                    @click.prevent="toggleAndSetLang(multiLang.lang)"
                    :title="$t('text.app.common.edit')"
                >
                    <Icon name="edit" width="18" height="18" /> 
                </a>
            </div>
        </div>

        <template
            v-for="(lang, index) in multiLang.userLanguages"
            :key="lang"
        >
            
            <div 
            v-if="lang != multiLang.lang" 
            class="flex justify-between mt-1">
                <div class="flex items-center">
                    <label for="">{{ isoLanguages[lang]?.name }}</label>
                </div>
                <div class="actions flex items-center gap-x-3">
                    <component 
                        :is="defaultLinkTag" 
                        href="#" 
                        class="ml-2"
                        :class="{ 'cursor-not-allowed': loading }"
                        :disabled="loading"
                        @click.prevent="setLanguageAndSave(lang)"
                        :title="$t('link.widget.multilanguage.set_default')"
                    >
                        <Icon 
                            name="home" 
                            width="16" 
                            height="16" 
                            color="rgba(26, 179, 148, 1)" 
                        />
                    </component>
                    <a 
                        href="#" 
                        class="text-sm hover:text-black"
                        :title="$t('text.app.common.edit')"
                        @click.prevent="toggleAndSetLang(lang)">
                        <Icon 
                            name="edit" 
                            width="18" 
                            height="18" 
                        /> 
                    </a>
                    <a 
                        v-if="lang != multiLang.lang"
                        href="#" 
                        class="text-sm hover:text-black"
                        :title="$t('text.app.common.delete')"
                        @click.prevent="deleteLang(index)">
                        <Icon 
                            name="times" 
                        />
                    </a>
                </div>
            </div>

        </template>
        

        <div class="form-group my-8 grid grid-cols-3 content-center gap-2">

            <div class="form-control col-span-2 text-sm">
                <SelectWithSearch
                    v-model="lang" 
                    :options="isoLanguages"
                    :userLanguages="multiLang.userLanguages"
                />
                <label class="text-xs text-gray-500">
                    {{ $t('link.widget.sidebar.multilanguage.select_lang_pack') }}
                </label>
            </div>

            <button 
                class="bg-dark-green text-white rounded col-span-1 text-sm"
                style="height: 2rem"
                @click.prevent="setUserLanguages"
                :disabled="addingNewLang || lang == ''"
                :class="{ 'cursor-not-allowed': addingNewLang || lang == '' }"
                :title="$t('text.app.common.add')"
            >
                {{ $t('text.app.common.add') }}
                <font-awesome-icon 
                    icon="circle-notch" 
                    class="fa-spin" 
                    v-show="addingNewLang" 
                />
            </button>

        </div>

        <EditLanguage :lang="langForProps" />
    
    </div>

</template>

<script setup>

import Swal from 'sweetalert2'
import SelectWithSearch from '@/shared/SelectWithSearch'
import { ref, computed, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import useModal from '@/composables/useModal'
import EditLanguage from './modals/EditLanguage/index'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

const isoLanguages = ref({})
const lang = ref('')
const langForProps = ref('')

const t = inject('t')
const store = useStore()
const { toggleModal } = useModal()
const { saveUserLanguages, setLanguage, deletedLangFromUserLangs, setLanguageAndSave } = useWidgetConfig()

const loading = computed(() => {
    const { value, id } = store.getters['widget/loading']
    return value && id == 'multiLang'
})

const addingNewLang = computed(() => {
    const { value, id } = store.getters['widget/loading']
    return value && id == 'addLang'
})

const defaultLinkTag = computed(() => loading.value ? 'p' : 'a')

const setUserLanguages = () => {
    const langCode = Object.entries(isoLanguages.value).find(([, { name }]) => {
        return name.toLowerCase() == lang.value.toLowerCase()
    })
    if(langCode?.length && !addingNewLang.value) {
        saveUserLanguages(langCode[0])
        lang.value = ''
    }
}

const toggleAndSetLang = (lang) => {
    langForProps.value = lang
    toggleModal('editLanguage', true)
}

const deleteLang = async (index) => {
    const result = await Swal.fire({
        title: t('notify.modules.components.common.prompt_delete'),
        text: t("notify.modules.components.multilanguage.prompt_delete_confirmation") + '!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1AB394',
        cancelButtonColor: '#d33',
        confirmButtonText: t('notify.modules.components.common.delete_confirmation') + '!'
    })
    if(result.isConfirmed) {
        deletedLangFromUserLangs(index)
    }
}

const setIsoLanguages = () => {
    isoLanguages.value = require('@/resources/isoLanguages.json') || {}
}

const multiLang = computed(() => store.getters['widget/multiLang'])

onMounted(() => setIsoLanguages())
        
       
</script>

