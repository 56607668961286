<template>

    <span 
        v-for="(colorBox, index) in colorBoxes"
        :key="index"
        class="color-box"
        :class="[colorBox.class, $attrs.classes]" 
        @click.prevent="saveColor(colorBox.color)"
    >
    </span>
    <span 
        class="bg-white color-box cursor-pointer text-gray-800 relative" 
        style="padding-left: 0.3rem; padding-right: 0.3rem" 
        @click.prevent="toggleColor"
    >
        <font-awesome-icon icon="ellipsis-h" class="text-sm" />
    </span>
    <input 
        type="color" 
        ref="colorPicker"
        v-model="color"
        class="float-right -mt-4 invisible opacity-0 h-0 w-0" 
    />

</template>

<script>
export default {
    inheritAttrs: false
}
</script>


<script setup>

    import { computed, ref } from 'vue'

    const props = defineProps({
        modelValue: {
            type: String,
            default: '#59c7f9'
        }
    })

    const emit =  defineEmits(['update:modelValue'])

    const showColorKit = ref(false)
    const colorPicker = ref(null)

    const color = computed({
        get: () => props.modelValue,
        set: (value) => emit('update:modelValue', value)
    })

    const closeKit = () => showColorKit.value = false

    const saveColor = (elemColor) => {
        color.value = elemColor
    }

    const toggleColor = () => {
        if(colorPicker.value instanceof Element) {
            colorPicker.value.click()
        } 
    }

    const toggleColorKit = () => {
        showColorKit.value = !showColorKit.value
    }

    const colorBoxes = computed(() => {
        return [{
            class: 'bg-green-600', color: 'rgba(5, 150, 105, 1)'
        },{
            class: 'bg-purple-600', color:'rgba(124, 58, 237, 1)'
        },{
            class: 'bg-red-600', color:'rgba(220, 38, 38, 1)'
        },{
            class: 'bg-black', color: 'rgba(0, 0, 0, 1)'
        },{
            class: 'bg-red-300', color: 'rgba(252, 165, 165, 1)'
        },{
            class: 'bg-blue-400', color: 'rgba(96, 165, 250, 1)'
        },{
            class: 'bg-yellow-500', color: 'rgba(245, 158, 11, 1)'
        },{
            class: 'bg-gray-700', color: 'rgba(55, 65, 81, 1)'
        }]
    })


</script>