<template>
  <div class="relative">
    <div class="font-inter">
      <div class="form-control">
        <div class="dropdown-with-arrow">
          <input
            type="search"
            name="search"
            class="block border-0 border-b-half w-full focus:outline-none dark:bg-gray-800 dark:text-off-white py-1 dark:placeholder-gray-100 border-light-green placeholder-gray-500 focus:border-green-700"
            :placeholder="$t('label.app.common.select_choose')"
            autocomplete="off"
            @click.stop="open"
            @focus="open"
            @keyup="navigate"
            @keyup.stop.esc="close"
            @keyup.stop.enter="updateAndClose(active)"
            :value="modelName()"
            @input="$emit('update:modelValue', $event.target.value)"
          />
        </div>
      </div>
    </div>

    <dropdown
      :id="dropdownId"
      v-if="isActiveDropdown(dropdownId)"
      classes="top-4 left-0.5"
      style="width: 100%; max-height: 18rem; overflow-y: auto"
      v-on-click-outside="close"
    >
      <template v-if="filteredOptions.length">
        <dropdown-item
          classes="dropdown-item bg-white hover:bg-gray-200"
          :class="{ 'active-dropdown': index == 0 }"
          style="
            padding-top: 5px !important;
            padding-bottom: 5px !important;
            display: flex;
            justify-content: space-between;
          "
          @click.prevent="updateAndClose(option.value)"
          v-for="(option, index) in filteredOptions"
          :key="option.value"
        >
          <span>{{ option.name }}</span>
          <span
            class="color-box ml-2 flex items-center"
            style="padding-bottom: 0; padding-top: 0"
            :style="{ background: option.background || option.default }"
          >
          </span>
        </dropdown-item>
      </template>
      <dropdown-item v-else>{{
        $t("label.app.common.no_match")
      }}</dropdown-item>
    </dropdown>
  </div>
</template>

<script>
export default {
  name: "ColorSchemesWithSearch",
  inheritAttrs: false,
};
</script>

<script setup>
import lodash from "lodash";
import { useAttrs, computed, ref } from "vue";
import useActiveToggler from "@/composables/useActiveToggler";
import { vOnClickOutside } from "@vueuse/components";

const props = defineProps({
  options: {
    type: [Object, Array],
    required: true,
  },
  modelValue: {
    type: [Number, String],
    required: false,
    default: "en",
  },
  userLanguages: {
    type: [Array, Object],
    required: false,
    default: () => [],
  },
});

const emit = defineEmits(["update:modelValue"]);

const active = ref("");

const attrs = useAttrs();

const dropdownId = computed(() => attrs.id || "customSelectDropdown");
const filteredOptions = computed(() => filter());

const modelName = () => {
  let obj = props.options.filter(function (ele) {
    if (ele.value == props.modelValue) {
      return ele;
    }
  });
  if (obj[0] == undefined) {
    return;
  } else {
    return obj[0].name;
  }
};

const filter = () => {
  return props.options.filter((option) => callback(option));
};

const callback = (option) => {
  if (props.modelValue) {
    return (
      option.name.toLowerCase().indexOf(props.modelValue.toLowerCase()) != -1
    );
  }
  return true;
};

const updateAndClose = (value) => {
  if (value == "" || value == null || value == undefined) return;
  emit("update:modelValue", value);
  toggleDropdown(dropdownId.value);
};

const close = () => {
  if (isActiveDropdown(dropdownId.value)) toggleDropdown(dropdownId.value);
};

const open = () => {
  if (isActiveDropdown(dropdownId.value) == false)
    toggleDropdown(dropdownId.value);
};

const navigate = (e) => {
  if (e.keyCode == 40 || e.keyCode == 38) {
    open();

    const items = document.querySelectorAll(".dropdown-item");
    const item = Object.entries(items).find(([, item]) => {
      return [...item.classList].includes("active-dropdown");
    });

    if (!lodash.isEmpty(item)) {
      const elem = item[1];
      const navigateToElement =
        e.keyCode == 40 ? elem.nextElementSibling : elem.previousElementSibling;
      elem.classList.remove("active-dropdown");

      if (navigateToElement == null) {
        const resetIndex = e.keyCode == 40 ? 0 : items.length - 1;
        items[resetIndex].classList.add("active-dropdown");
        active.value = items[resetIndex].innerText;
      } else {
        navigateToElement.classList.add("active-dropdown");
        active.value = navigateToElement.innerText;
      }
    }
  }
};

const { toggleDropdown, isActiveDropdown } = useActiveToggler();
</script>

<style lang="scss" scoped>
.dropdown::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: -12px;
  right: -1px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.active-dropdown {
  background: rgba(229, 231, 235, 1);
}
</style>
