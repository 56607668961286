import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const {
  checkRequired,
  checkValueNotIn,
  checkEmail,
  checkIsNumber,
  errors,
  clearErrors,
  isValidUrl,
} = validator();

export function validatePhone(phone, config) {
  clearErrors();

  phone = unref(phone);
  config = unref(config);

  checkRequired("name", phone);
  //checkValueNotIn('payment_method', phone)
  checkValueNotIn("plan_category", phone);
  checkValueNotIn("plan", phone);
  checkValueNotIn("billing_period", phone);
  checkRequired("gdpr", config);
  checkRequired("terms", config);
  isValidUrl("gdpr", config);
  isValidUrl("terms", config);

  return hasErrors.value ? false : true;
}

export function validateContact(contact) {
  clearErrors();

  contact = unref(contact);

  checkRequired("name", contact);
  checkRequired("timezone", contact);
  checkRequired("address", contact);

  return hasErrors.value ? false : true;
}

export function validateDepartment(department) {
  clearErrors();

  department = unref(department);

  checkRequired("title", department.mainSettings);

  if (department.mainSettings.contactOptions.email) {
    checkRequired("email", department.mainSettings);
    checkEmail("email", department.mainSettings);
  }

  if (department.mainSettings.contactOptions.phoneCalls) {
    checkRequired("phoneNumber", department.mainSettings);
    checkIsNumber("phoneNumber", department.mainSettings);
  }

  return hasErrors.value ? false : true;
}
