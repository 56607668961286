<template>
  <ul class="tariff-types">
    <li
      v-for="(type, index) in types"
      :key="index"
      :class="{
        active:
          route.params.id == type.id ||
          (type.name == 'Tariff SMS' && route.params?.id == undefined),
      }"
      @click.prevent="
        $router.replace({
          name: `tariffs-type`,
          params: {
            id: type.id,
          },
        })
      "
    >
      {{ type.name }}
    </li>
  </ul>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useTariffs from "../../composables/useTariffs";

const store = useStore();
const route = useRoute();

const { getTariffTypes } = useTariffs();

const types = computed(() => store.getters[`tariffs/types`]);

onMounted(() => {
  getTariffTypes();
});
</script>

<style lang="scss" scoped>
ul.tariff-types {
  margin-left: 2rem;

  li {
    padding-left: 1.5rem;
    cursor: pointer;

    &::before {
      content: "•";
      position: relative;
      left: -0.5rem;
      top: 0;
    }

    &:hover {
      font-weight: 500;
    }
  }

  li.active {
    font-weight: 500;
  }
}
</style>
