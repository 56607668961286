<template>
    <div class="sidebar-section p-4 py-6 text-sm">

        <section v-if="sidebar">
            
            <div class="color-scheme grid grid-cols-12 my-8">

                <div class="col-span-5">
                    {{ $t('link.widget.sidebar.color_scheme') }}: 
                    <a 
                        href="#" 
                        class="text-dark-green underline text-xs block" 
                        @click.prevent="resetColorScheme({ value: '#1AB394' })"
                    >
                        {{ $t('link.widget.sidebar.reset') }}
                    </a>
                </div>

                <div class="col-span-7 relative">
                    <color-picker 
                        v-model="sidebar.colorScheme" 
                        @update:modelValue="saveThrottledSidebar" 
                        classes="py-0.5"
                    />
                </div>

            </div>

            <div class="postion-section my-8 grid grid-cols-12 pr-4">
                
                <label for="textLabel" class="col-span-5">
                    {{ $t('label.widget.sidebar.sidebar.position') }}:
                </label>


                <div class="form-group flex w-full justify-between col-span-7 ml-2">
                    <div>
                        <input 
                            type="radio" 
                            name="position" 
                            value="left" 
                            id="leftPosition" 
                            v-model="sidebar.position"
                            @change="saveThrottledSidebar"
                        />
                        <label for="leftPosition" class="dark:text-off-white">
                            {{ $t('text.app.common.left') }}
                            <span class="radio-button dark:bg-gray-800"></span>
                        </label>
                    </div>
                    <div>
                        <input 
                            type="radio" 
                            name="position" 
                            value="right" 
                            id="rightPosition" 
                            v-model="sidebar.position"
                            @change="saveThrottledSidebar"
                        />
                        <label for="rightPosition" class="dark:text-off-white">
                            {{ $t('text.app.common.right') }}
                            <span class="radio-button dark:bg-gray-800"></span>
                        </label>
                    </div>
                </div>
                
            </div>

            <div class="my-8 grid grid-cols-12">
                
                <label for="textLabel" class="col-span-5">
                    {{ $t('label.widget.sidebar.sidebar.animation') }}:
                </label>

                <select 
                    v-model="sidebar.animation" 
                    class="form-group border-0 border-b-half border-light-green focus:outline-none flex w-full justify-between col-span-7"
                    @change="saveThrottledSidebar"
                >
                    <option value="ripple">Ripple</option>
                    <option value="bounce">Bounce</option>
                    <option value="shake">Shake</option>
                    <option value="wiggle">Wiggle</option>
                </select>
                    
            </div>

        </section>


    </div>

</template>

<script setup>

import lodash from 'lodash'
import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import colorPicker from './color-picker'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

const store = useStore()
const { saveSidebar, saveThrottledSidebar, resetColorScheme } = useWidgetConfig()
const sidebar = computed(() => store.getters['widget/sidebar'])

watch(sidebar, (value) => {
    if(!lodash.isEmpty(value)) {
        const input = document.querySelector(`input[name="position"][value="${sidebar.value.position}"]`)
        if(input) input.checked = true
    }
})

</script>