<template>

    <li class="relative flex items-center mb-2.5">
        <a href="#" class="flex-grow hover:text-black dark:hover:text-gray-100">{{ title }}</a>

        <div>

            <Icon 
                name="elipsis"
                class="text-sm  cursor-pointer"
                color="rgba(26, 179, 148, 1)"
                @click.prevent="toggleDropdown(`department${title}`)"
                :width="30"
            />

            <dropdown 
            classes="w-64 p-2 dropdown-edit-department"
            :id="`department${title}`"
            v-if="isActiveDropdown(`department${title}`)"
            >
                <dropdown-item 
                classes="dropdown-item mb-1.5" 
                @click.prevent="openEditAndActiveDepartment">
                    <Icon name="edit" class="inline" />
                    <span class="inline ml-1.5">{{ $t('text.widget.sidebar.contacts.edit_department') }}</span>
                </dropdown-item>

                <dropdown-item 
                classes="dropdown-item"
                @click.prevent="remove"
                >
                    <Icon name="times" class="inline" />
                    <span class="inline ml-1.5">{{ $t('text.widget.sidebar.contacts.delete_department') }}</span>
                </dropdown-item>

            </dropdown>

        </div>


    </li>

</template>

<script setup>

import Swal from 'sweetalert2'
import useActiveToggler from '@/composables/useActiveToggler'
import useModal from '@/composables/useModal'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'
import { inject } from 'vue'
    

const props = defineProps({
    id: {
        required: true,
        type: [String, Number]
    },
    title: {
        required: true,
        type: String
    },
    contactId: {
        required: true,
        type: [String, Number]
    }
})    

const t = inject('t')
const { toggleModal } = useModal()
const { toggleDropdown, isActiveDropdown } = useActiveToggler()
const { deleteDepartment, setActiveResource, reset } = useWidgetConfig()
    
const openEditAndActiveDepartment = async () => {
    await setActiveResource({ contactId: props.contactId, departmentId: props.id })
    toggleModal('editDepartment', true)
    toggleDropdown(`department${props.title}`)
}

const remove = async () => {

    const result = await Swal.fire({
        title: t('notify.modules.components.common.prompt_delete'),
        text: t("notify.modules.components.modal.department.prompt_delete_confirmation") + '!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1AB394',
        cancelButtonColor: '#d33',
        confirmButtonText: t('notify.modules.components.common.delete_confirmation') + '!'
    })

    if(result.isConfirmed) {
        await deleteDepartment(props.id, props.contactId)
        toggleDropdown(`department${props.title}`)
        reset()
    }

}

        
</script>