<template>
  <modal
    @toggle="toggleModal('editLanguage')"
    :open="isOpenModal('editLanguage')"
    content-height="450px"
  >
    <template #header>{{
      $t("label.widget.components.modal.edit_language.edit")
    }}</template>

    <template #content>
      <div v-if="translatedLanguage">
        <LangInput
          v-for="(input, index) in langInputs"
          :key="index"
          :class="{ 'mt-3': index == 0 }"
          :label="$t(input.label)"
          v-model="editLang[input.model]"
        />
      </div>
    </template>

    <template #footer>
      <BaseButton
        :text="$t('button.label.cancel')"
        @click.prevent="toggleModal('editLanguage')"
        kind="default"
        :title="$t('button.label.cancel')"
      />

      <BaseButton
        :text="$t('button.label.save-changes')"
        @click.prevent="saveLanguage"
        :disabled="loading"
        :classes="loading ? 'cursor-not-allowed' : ''"
        :title="$t('button.label.save-changes')"
      >
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </BaseButton>
    </template>
  </modal>
</template>

<script setup>
import lodash from "lodash";
import { useStore } from "vuex";
import { computed, reactive, watch, unref, ref, onMounted } from "vue";
import modal from "@/shared/modal";
import LangInput from "./LangInput";
import useModal from "@/composables/useModal";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";

const props = defineProps({
  lang: {
    type: String,
    default: "en",
  },
});

const translatedLanguage = ref({});
const editLang = reactive({
  contactList: "",
  bestCallbackTime: "",
  bestCallbackTimePara: "",
  callMeNow: "",
  callMeLater: "",
  callForFree: "",
  calling: "",
  noNewMessages: "",
  chatWithAPerson: "",
  term: "",
  email: "",
  emailPlaceholder: "",
  home: "",
  webcall: "",
  videocall: "",
  callback: "",
  fullname: "",
  fullnamePlaceholder: "",
  phone: "",
  phonePlaceholder: "",
  subject: "",
  message: "",
  send: "",
  leaveUsAMessage: "",
  widgetDialog: "",
  sendNumber: "",
  widgetButtonLabel: "",
  widgetOnlineStatus: "",
  widgetOfflineStatus: "",
  messageSent: "",
  mute: "",
  unmute: "",
  resumeCall: "",
  holdCall: "",
  contactUs: "",
  widgetInfo: "",
  dialingNumberPlaceholder: "",
  widgetFirstStartScreen: "",
  widgetAcceptTerm: "",
  firstScreenSubmit: "",
  emailSendSuccessfully: "",
  cantSentEmail: "",
  cantSentEmailForbidden: "",
  cantSentEmailServerError: "",
  cantSentEmailNoAuthorization: "",
  callbackAddSuccessfully: "",
  cantAddCallback: "",
  cantAddCallbackForbidden: "",
  cantAddCallbackServerError: "",
  cantAddCallbackNoAuthorization: "",
  pleaseEnterYourName: "",
  pleaseEnterYourEmail: "",
  pleaseEnterValidEmail: "",
  pleaseEnterYourPhone: "",
  pleaseEnterValidPhoneWithCountry: "",
  pleaseEnterSubject: "",
  pleaseEnterMessage: "",
  fileSizeEmailLimit: "",
  pleaseAcceptTermAndCondition: "",
  fileIsBiggerThan20MB: "",
});

const store = useStore();
const { toggleModal, isOpenModal } = useModal();
const { saveUserEditLangs } = useWidgetConfig();

const langInputs = computed(() => {
  return Object.entries(editLang).map(([key]) => {
    return {
      label: `label.widget.multilanguage.modal.${lodash.kebabCase(key)}`,
      model: key,
    };
  });
});

const multiLang = computed(() => store.getters["widget/multiLang"]);
const loading = computed(() => {
  const { value, id } = store.getters["widget/loading"];
  return value && id == "multiLang";
});

const getLangValue = (key) => {
  const translations = require(`@/resources/translations/en.json`);
  return translations[`label.widget.multilanguage.modal.${key}`];
};

const setEditLangValue = () => {
  if (!props.lang) return;
  for (let [key] of Object.entries(unref(editLang))) {
    const userSavedLang = multiLang.value.userEditedLangs[props.lang];
    if (userSavedLang && userSavedLang[lodash.kebabCase(key)] != "") {
      editLang[key] = userSavedLang[key] || "";
    } else editLang[key] = getLangValue(lodash.kebabCase(key)) || "";
  }
};

const saveLanguage = async () => {
  if (loading.value) return;
  await saveUserEditLangs({ key: props.lang, translations: unref(editLang) });
  toggleModal("editLanguage");
};

watch(
  () => props.lang,
  () => setEditLangValue()
);

onMounted(() => setEditLangValue());
</script>
