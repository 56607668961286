<template>
  <modal
    @toggle="toggleModal('addDepartment')"
    :open="isOpenModal('addDepartment')"
  >
    <template #header>{{ $t('heading.widget.modal.department.add') }}</template>

    <template #content>
      <department-tabs
        :config="activeConfig.id"
        @toggle="toggleConfig($event)"
      />

      <main-settings v-show="!isActiveConfig('openingHours')" />
      <opening-hours v-show="isActiveConfig('openingHours')" />
    </template>

    <template #footer>
      <BaseButton
        kind="default"
        :text="$t('button.label.cancel')"
        @click.prevent="toggleModal"
        :title="$t('button.label.cancel')"
      />

      <BaseButton
        :text="$t('button.label.save-changes')"
        @click.prevent="add"
        :title="$t('button.label.save-changes')"
        :disabled="loading"
      >
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </BaseButton>
    </template>
  </modal>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { inject, unref, onMounted, computed } from 'vue'

import { isPossibleNumber } from 'libphonenumber-js'

import modal from '@/shared/modal'
import openingHours from '../contacts-section/opening-hours'
import mainSettings from '../contacts-section/deparment-main-settings'
import departmentTabs from '../contacts-section/department-tabs'

import useModal from '@/composables/useModal'
import useActiveToggler from '@/composables/useActiveToggler'
import useWidgetConfig from '../../../composables/useWidgetConfig'
import useThemeToggler from '@/composables/useThemeToggler'
import useToaster from '@/composables/useToaster'
import { validateDepartment } from '@/composables/auth/widgetValidations'
import validator from '@/composables/auth/validator'

const { trigger } = useToaster()
const { toggleConfig, isActiveConfig, activeConfig } = useActiveToggler()
const { toggleModal, isOpenModal } = useModal()
const { addDepartment, reset, fixErrorsAlert, department, loading } =
  useWidgetConfig()
const { isDarkthemeMode } = useThemeToggler()
const { clearErrors, errors, setErrors } = validator()
const t = inject('t')
const notify = inject('notify')

const add = async () => {
  const email = department.mainSettings.email;
  const title = department.mainSettings.title;
 
  if (!validateDepartment(department)) {
    fixErrorsAlert()
    return
  }

  let phoneNumber = department.mainSettings.phoneNumber
  let plan = department.plan
  
  if (plan?.value?.features?.pbx) {
    if (!(phoneNumber?.length >= 2 && phoneNumber?.length <= 5))
      if (!phoneNumber.includes('+')) {
        notify({
          message: t('valiation.pages.common.phone_number_incorrect'),
          type: 'danger'
        })
        setErrors({
          key: 'phoneNumber',
          message: t('valiation.pages.common.phone_number_incorrect')
        })
        return
      } else if (phoneNumber.includes('+')) {
        if (!isPossibleNumber(phoneNumber)) {
          notify({
            message: t('valiation.pages.common.phone_number_incorrect'),
            type: 'danger'
          })
          setErrors({
            key: 'phoneNumber',
            message: t('valiation.pages.common.phone_number_incorrect')
          })
          return
        }
      }
  } else {
    if (phoneNumber?.length >= 2 && phoneNumber?.length <= 5) {
      notify({
        message: t('valiation.pages.common.phone_number_incorrect'),
        type: 'danger'
      })
      setErrors({
        key: 'phoneNumber',
        message: t('valiation.pages.common.phone_number_incorrect')
      })
      return
    }
    if (!isPossibleNumber(phoneNumber)) {
      notify({
        message: t('valiation.pages.common.phone_number_incorrect'),
        type: 'danger'
      })
      setErrors({
        key: 'phoneNumber',
        message: t('valiation.pages.common.phone_number_incorrect')
      })
      return
    }
  }

  await addDepartment(email, title, phoneNumber)
  if (isEmpty(errors.value)) {
    toggleModal('addDepartment')
    reset()
  }
}

onMounted(() => {
  clearErrors()
  toggleConfig('mainSettings')
})
</script>
