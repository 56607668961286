<template>
  <ul class="reports-list">
    <li
      v-for="(list, index) in lists"
      :key="index"
      :class="{ active: $route.query.tab == list.active }"
      :title="$t(`link.widget.sidebar.${list.title}`)"
      @click.prevent="
        $router.push({
          name: list.title,
        })
      "
    >
      {{ $t(`link.widget.sidebar.${list.title}`) }}
    </li>
    <!-- <li
      v-for="(list, index) in lists"
      :key="index"
      :class="{ active: $route.query.tab == list.active }"
      :title="$t(`link.widget.sidebar.${list.title}`)"
      @click.prevent="
        $router.push({
          name: 'report-stats',
          query: {
            tab: list.active,
          },
        })
      "
    >
      {{ $t(`link.widget.sidebar.${list.title}`) }}
    </li> -->
  </ul>
</template>

<script>
export default {
  name: "report-list",
};
</script>

<script setup>
import { ref } from "vue";

const lists = ref([
  {
    title: "calls",
    icon: "phone-ringing",
    active: "calls",
  },
  {
    title: "callRequests",
    icon: "phone-alt",
    active: "call-requests",
  },
  {
    title: "videoCalls",
    icon: "video",
    active: "video-calls",
  },
  {
    title: "emails",
    icon: "email",
    active: "emails",
  },
  {
    title: "messages",
    icon: "comment",
    active: "messages",
  },
]);
</script>

<style lang="scss" scoped>
ul.reports-list {
  margin-left: 2rem;

  li {
    padding-left: 1.5rem;
    cursor: pointer;

    &::before {
      content: "•";
      position: relative;
      left: -0.5rem;
      top: 0;
    }

    &:hover {
      font-weight: 500;
    }
  }

  li.active {
    font-weight: 500;
  }
}
</style>
