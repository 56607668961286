<template>

    <div>
        <div 
            class="grid grid-cols-12 items-center gap-3 form-group text-xl my-3 font-inter" 
        >

            <div class="col-span-12">
                
                <label class="font-bold mt-2">
                    {{ $t('label.app.common.backgrounds') }}
                </label>

                <ColorSchemesWithSearch 
                    :options="backgrounds"
                    v-model="background"
                    id="backgroundSchemes"
                />

            </div>

            <div class="col-span-12 relative">
                <color-picker 
                    v-model="appearance[background]" 
                    @update:modelValue="saveThrottledAppearance"
                    classes="py-0" 
                />
            </div>

        </div>

        <a 
            href="#" 
            class="text-dark-green underline text-xs block" 
            @click.prevent="resetAppearance(backgrounds)"
        >
            {{ $t('link.widget.sidebar.reset') }}
        </a>

    </div>

    <div>

        <div 
            class="grid grid-cols-12 items-center gap-3 form-group text-xl my-3 font-inter" 
        >
            <div class="col-span-12">

                <label for="" class="font-bold mt-2">
                    {{ $t('label.app.common.colors') }}
                </label>
                
                <ColorSchemesWithSearch 
                    :options="colorSchemes"
                    v-model="color"
                    id="colorSchemes"
                />

            </div>

            <div class="col-span-12 relative">
                <color-picker 
                    v-model="appearance[color]" 
                    @update:modelValue="saveThrottledAppearance" 
                />
            </div>

        </div>

        <a 
            href="#" 
            class="text-dark-green underline text-xs block" 
            @click.prevent="resetAppearance(colorSchemes)"
        >
            {{ $t('link.widget.sidebar.reset') }}
        </a>

    </div>


</template>

<script setup>

import { ref, computed, inject } from 'vue'
import { useStore } from 'vuex'
import colorPicker from '../color-picker'
import ColorSchemesWithSearch from '@/shared/ColorSchemesWithSearch'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

const t = inject('t')
const store = useStore()

const background = computed({
    set(value) {
        setAppearanceResource({ resource: 'appearanceBackground', value })
    },
    get() {
        return store.getters['widget/appearanceBackground']
    }
})

const color = computed({
    set(value) {
        setAppearanceResource({ resource: 'appearanceColor', value })
    },
    get() {
        return store.getters['widget/appearanceColor']
    }
})

const { saveThrottledAppearance, resetAppearance, setAppearanceResource } = useWidgetConfig()

const appearance = computed(() => store.getters['widget/appearance'])


const backgrounds = computed(() => {
    const options =  [{
        name: 'body_background',
        default: '#fff',
        value: 'bodyBackground'
    },{
        name: 'header_background',
        default: '#000',
        value: 'headerBackground'
    },{
        name: 'footer_background',
        default: '#000',
        value: 'footerBackground'
    },{
        name: 'header_icon_background',
        default: '#fff',
        value: 'headerIconBackground'
    },{
        name: 'contact_list_background',
        default: '#E0E0E0',
        value: 'contactListBackground'
    },{
        name: 'department_background',
        default: '#EBEBF3',
        value: 'departmentBackground'
    },{
        name: 'department_list_background',
        default: '#bbbbd7',
        value: 'departmentListBackground'
    },{
        name: 'department_list_icon',
        default: '#fff',
        value: 'departmentListIcon'
    },{
        name: 'department_list_active_icon',
        default: '#BBBBD7',
        value: 'departmentListActiveIcon'
    },{
        name: 'sidebar_background',
        default: '#fff',
        value: 'sidebarBackground'
    },{
        name: 'sidebar_icon_background',
        default: '#EBEBF3',
        value: 'sidebarIconBackground'
    },{
        name: 'dialer_button_bg',
        default: '#fff',
        value: 'dialerButtonBg'
    },{
        name: 'call_hang_bg',
        default: '#F52323',
        value: 'callHangBg'
    },{
        name: 'submit_button_bg',
        default: 'rgb(187, 187, 215)',
        value: 'submitButtonBg'
    }]
    return options.map(option => {
        return {
            ...option,
            name: t( `link.widget.sidebar.${option.name}`),
            background: appearance.value[option.value]
        }
    })
})

const colorSchemes = computed(() => {
    const options = [{
        name: 'body_color',
        default: '#9b9bc5',
        value: 'bodyColor'
    },{
        name: 'header_color',
        default: '#fff',
        value: 'headerColor'
    },{
        name: 'footer_color',
        default: '#fff',
        value: 'footerColor'
    },{
        name: 'header_icon_color',
        default: '#BBBBD7',
        value: 'headerIconColor'
    },{
        name: 'contact_list_color',
        default: '#000',
        value: 'contactListColor'
    },{
        name: 'department_list_color',
        default: '#fff',
        value: 'departmentListColor'
    },{
        name: 'department_list_icon_color',
        default: '#BBBBD7',
        value: 'departmentListIconColor'
    },{
        name: 'department_list_active_icon_color',
        default: '#fff',
        value: 'departmentListActiveIconColor'
    },{
        name: 'sidebar_icon_color',
        default: '#9B9BC5',
        value: 'sidebarIconColor'
    },{
        name: 'dialer_button_color',
        default: '#000',
        value: 'dialerButtonColor'
    },{
        name: 'call_hang_color',
        default: '#fff',
        value: 'callHangColor'
    },{
        name: 'submit_button_color',
        default: '#fff',
        value: 'submitButtonColor'
    }]
    return options.map(option => {
        return {
            ...option,
            name: t( `link.widget.sidebar.${option.name}`),
            background: appearance.value[option.value]
        }
    })
})

</script>

<style scoped>
.color-box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
</style>
