<template>
  <modal @toggle="toggleModal('editPhone')" :open="isOpenModal('editPhone')">
    <template #header>
      {{ $t("heading.widget.modal.phone_modal.edit_phone") }}
    </template>

    <template #content>
      <PhoneForm />
    </template>

    <template #footer>
      <BaseButton
        kind="default"
        :text="$t('button.label.cancel')"
        @click.prevent="toggleModal"
        :title="$t('button.label.cancel')"
      />

      <BaseButton
        :text="$t('button.label.save-changes')"
        @click.prevent="edit"
        :title="$t('button.label.save-changes')"
        :disabled="loading"
      >
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </BaseButton>
    </template>
  </modal>
</template>

<script setup>
import { cloneDeep, isEmpty } from "lodash";
import { useStore } from "vuex";
import PhoneForm from "./PhoneForm";
import { onMounted, inject, provide, ref, reactive } from "vue";
import modal from "@/shared/modal";
import useModal from "@/composables/useModal";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";
import usePhone from "@/modules/widget/composables/usePhone";
import validator from "@/composables/auth/validator";

const t = inject("t");
const store = useStore();
const { toggleModal, isOpenModal } = useModal();

const {
  loading,
  editPhone,
  validatePhone,
  getBillingPeriods,
  subscriptionPlans,
  getSubscriptionPlans,
  getCategoryPlanNames,
} = usePhone();

const { errors } = validator();
const { fixErrorsAlert } = useWidgetConfig();

const phone = ref(cloneDeep(store.getters["widget/activeWidget"]));

const config = reactive({
  gdpr: null,
  terms: null,
});

const edit = async () => {
  if (!validatePhone(phone, config)) {
    fixErrorsAlert();
    return;
  }
  await editPhone(phone, config);
  if (isEmpty(errors.value)) toggleModal("editPhone");
};

const fillPhoneCategory = () => {
  const phoneName = subscriptionPlans.value.find(
    (plan) => plan.id == phone.value.plan
  );
  if (!isEmpty(phoneName)) {
    phone.value.plan_category = phoneName.plan;
    phone.value.plan = phoneName.name;
    phone.value.billing_period = phoneName.billing_period;
    getCategoryPlanNames(phone.value.plan_category);
    getBillingPeriods(phone.value.plan, phone.value.plan_category);
  }
};

provide("phone", phone);
provide("config", config);

const setConfig = () => {
  config.gdpr = phone.value?.config?.gdpr;
  config.terms = phone.value?.config?.terms;
};

onMounted(async () => {
  await getSubscriptionPlans();
  fillPhoneCategory();
  setConfig();
});
</script>
