import moment from "moment";
import { ref, computed, unref } from "vue";
import { upperFirst } from "lodash";
import store from "@/store/index";
import useAuth from "@/composables/auth/authenticate";
import useHelpers from "@/composables/useHelpers";
import i18nSetup from "@/services/i18nSetup";
import { validatePhone } from "@/composables/auth/widgetValidations";

import {
  GET_WIDGETS,
  RESET_PHONE,
  SAVE_PHONE,
  EDIT_PHONE,
  SET_ACTIVE_WIDGET,
  DELETE_PHONE,
} from "../store/types";

const { i18n } = i18nSetup();
const translate = i18n.global.t;

const { getAuth: auth } = useAuth();

const defaults = [
  {
    value: 0,
    name: "Please select",
  },
];

const widget = computed(() => store.getters["widget/widget"]);
const subscriptionPlans = computed(() => store.getters["subscriptionPlans"]);
const { getSubscriptionPlans, formattedPrice } = useHelpers();
const categoryPlanNames = ref(defaults);
const billingPeriods = ref(defaults);

export default function usePhone() {
  const loading = computed(() => {
    const { id, value } = store.getters["widget/loading"];
    return id == "widget" && value;
  });

  const setActivePhone = (phone) => {
    phone = unref(phone);
    store.commit(`widget/${SET_ACTIVE_WIDGET}`, phone.id);
    return store.dispatch(`widget/${SET_ACTIVE_WIDGET}`, phone.id);
  };

  const addPhone = (phone, config) => {
    const subscription = subscriptionPlans.value.find(
      (i) =>
        i.billing_period === phone.billing_period &&
        i.name === phone.plan &&
        i.plan === phone.plan_category
    );
    const subscriptionId = subscription.id;

    const phoneData = {
      name: phone.name,
      is_active: phone.is_active,
      plan: subscriptionId,
      contacts: [],
    };

    return store.dispatch(`widget/${SAVE_PHONE}`, {
      phone: unref(phoneData),
      config: unref(config),
    });
  };

  const resetPhone = () => {
    store.commit(`widget/${RESET_PHONE}`);
  };

  const getPhoneData = (phone) => {
    const subscription = subscriptionPlans.value.find(
      (i) =>
        i.billing_period === phone.billing_period &&
        i.name === phone.plan &&
        i.plan === phone.plan_category
    );
    const subscriptionId = subscription.id;

    return {
      name: phone.name,
      is_active: phone.is_active || true,
      plan: subscriptionId || null,
      contacts: phone.contacts,
      appearance: phone.appearance,
      default: phone.default,
      id: phone.id,
      user_id: phone.user_id,
      ip_blacklist: phone.ip_blacklist,
      multilanguage: phone.multilanguage,
      my_country: phone.my_country,
      sidebar: phone.sidebar,
    };
  };

  const editPhone = (phone, config) => {
    phone = unref(phone);
    const phoneData = getPhoneData(phone);
    return store.dispatch(`widget/${EDIT_PHONE}`, {
      phone: phoneData,
      // phone: unref(phone),
      config: unref(config),
    });
  };

  const deletePhoneData = (phone) => {
    phone = unref(phone);
    return store.dispatch(`widget/${DELETE_PHONE}`, phone);
  };

  const paymentOptions = computed(() => {
    if (auth.value) {
      const methods = auth.value.payment_methods.map((method) => {
        return {
          value: method.id,
          name:
            upperFirst(method.card_type?.replace("_", " ")) +
            " (" +
            method.last_four +
            " )",
        };
      });
      return [...defaults, ...methods];
    }
    return defaults;
  });

  const formattedCategories = computed(() => {
    const categories = subscriptionPlans.value
      .map(({ plan }, index) => {
        if (
          index ==
          subscriptionPlans.value.findIndex((subPlan) => subPlan.plan == plan)
        ) {
          return {
            value: plan,
            name: upperFirst(plan),
          };
        }
        return null;
      })
      .filter((category) => category != null);

    return [...defaults, ...categories];
  });

  const planNames = computed(() => {
    const names = subscriptionPlans.value.map((plan) => {
      return {
        ...plan,
        name: upperFirst(plan.name),
        value: plan.name,
      };
    });

    return [...defaults, ...names];
  });

  const getCategoryPlanNames = (category) => {
    const uniquePlans = [];
    const planExists = (value) =>
      !!uniquePlans.find((plan) => plan.value == value);

    planNames.value.forEach((plan) => {
      if (plan.plan == category && !planExists(plan.value)) {
        uniquePlans.push(plan);
      }
    });

    categoryPlanNames.value = [...defaults, ...uniquePlans];
  };

  const getBillingPeriods = (planName, categoryName) => {
    let periods = subscriptionPlans.value
      .filter((plan) => plan.name == planName && plan.plan == categoryName)
      .map((plan) => {
        return {
          name: plan.billing_period + " " + formattedPrice(plan.price),
          value: plan.billing_period,
        };
      });

    billingPeriods.value = [...defaults, ...periods];
  };

  const options = computed(() => {
    return [
      {
        name: translate("label.widget.modal.phone_modal.availability_active"),
        value: true,
      },
      {
        name: translate("label.widget.modal.phone_modal.availability_inactive"),
        value: false,
      },
    ];
  });

  return {
    options,
    loading,
    addPhone,
    planNames,
    editPhone,
    resetPhone,
    validatePhone,
    paymentOptions,
    setActivePhone,
    billingPeriods,
    deletePhoneData,
    subscriptionPlans,
    getBillingPeriods,
    categoryPlanNames,
    formattedCategories,
    getCategoryPlanNames,
    getSubscriptionPlans,
  };
}
