<template>
    <div class="flex justify-center items-center"> 
        <span 
            class="bg-grayish p-2 mr-6 sidebar-list-plus-icon cursor-pointer text-xs flex justify-center items-center" 
            @click.prevent="toggleDepartmentAndActivateContact(contact)" 
            v-show="isContactActive(contact.id)"
            :title="$t('text.app.common.add-new', { name: 'Department' })"
        >
            <Icon name="plus" />
        </span>

        <span class="relative">
            
            <Icon 
                name="elipsis" 
                @click.prevent="toggleDropdown(contact.slug)"
                class="text-sm cursor-pointer"
                :width="30"
            />

            <dropdown 
            classes="w-48 p-2 dropdown-edit-office"
            :id="contact.slug || contact.id"
            v-if="isActiveDropdown(contact.slug || contact.id)"
            >
                <dropdown-item 
                classes="dropdown-item mb-1.5" 
                @click.prevent="toggleOfficeAndActivateContact(contact)">
                    <Icon name="edit" class="inline" />
                    <span class="inline ml-2">{{ $t('text.widget.sidebar.contacts.edit_office') }}</span>
                </dropdown-item>

                <dropdown-item 
                classes="dropdown-item"  
                @click.prevent="remove(contact.id)">
                    <Icon name="times" class="inline" />
                    <span class="inline ml-2">{{ $t('text.widget.sidebar.contacts.delete_office') }}</span>
                </dropdown-item>

            </dropdown>

        </span>

    </div>
</template>

<script setup>

import { inject } from 'vue'
import Swal from 'sweetalert2'
import { useRoute } from 'vue-router'
import useActiveToggler from '@/composables/useActiveToggler'
import useModal from '@/composables/useModal'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'


defineProps({
    contact: {
        required: true,
        type: Object
    }
})

const t = inject('t')
const { toggleModal } = useModal()
const { isActiveDropdown, toggleDropdown } = useActiveToggler()
const { deleteContact, setActiveResource, reset } = useWidgetConfig()

const route = useRoute()  

const isContactActive = (contactId) => {
    const routes = ['Home', 'contacts', 'contact-departments']
    return routes.includes(route.name) && route.params?.id == contactId
}

const toggleDepartmentAndActivateContact = async (contact) => {
    await reset()
    await setActiveResource({ contactId: contact.id })
    toggleModal('addDepartment', true)
}

const toggleOfficeAndActivateContact = async (contact) => {
    await reset()
    await setActiveResource({ contactId: contact.id })
    toggleModal('editOffice', true)
    toggleDropdown(contact.slug)
}

const remove = async (id) => {

    const result = await Swal.fire({
        title: t('notify.modules.components.common.prompt_delete'),
        text: t("notify.modules.components.modal.contact.prompt_delete_confirmation") + '!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1AB394',
        cancelButtonColor: '#d33',
        confirmButtonText: t('notify.modules.components.common.delete_confirmation') + '!'
    })

    if(result.isConfirmed) {
        await deleteContact(id)
        toggleDropdown(props.contact.slug)
    }

}
        
</script>