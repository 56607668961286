<template>
    
    <section id="mainSettings" v-if="'id' in department">

        <form @input="removeError($event.target.name)" @change="removeError($event.target.name)">

            <div class="text-large leading-6 font-inter mb-6 mt-3">

                <label for="introduction" class="font-bold">
                    {{ $t('label.widget.modal.department.select_contact') }}
                </label>

                <div class="grid grid-cols-2 mt-3">

                    <FormCheck 
                        name="phoneCalls"
                        :label="$t('label.widget.modal.department.phone_calls')"
                        :modelValue="department.mainSettings.contactOptions.phoneCalls"
                        :disabled="checkInput('audio')"
                        @update:modelValue="updateDepartment({ value: $event, name: 'phoneCalls'})"
                    />

                    <FormCheck 
                        name="requestCallback"
                        :label="$t('label.widget.modal.department.request_callback')"
                        :modelValue="department.mainSettings.contactOptions.requestCallback"
                        :disabled="checkInput('callback')"
                        @update:modelValue="updateDepartment({ value: $event, name: 'requestCallback'})"
                    />

                    <FormCheck 
                        name="chat"
                        :label="$t('label.widget.modal.department.chat')"
                        :modelValue="department.mainSettings.contactOptions.chat"
                        :disabled="checkInput('message')"
                        @update:modelValue="updateDepartment({ value: $event, name: 'chat'})"
                    />

                    <FormCheck 
                        name="email"
                        :label="$t('label.widget.modal.department.email')"
                        :modelValue="department.mainSettings.contactOptions.email"
                        @update:modelValue="updateDepartment({ value: $event, name: 'email'})"
                    />

                    <FormCheck 
                        name="videoCalls"
                        :label="$t('label.widget.modal.department.video_calls')"
                        :modelValue="department.mainSettings.contactOptions.videoCalls"
                        :disabled="checkInput('video')"
                        @update:modelValue="updateDepartment({ value: $event, name: 'videoCalls'})"
                    />

                </div>

                <label for="name" class="text-xs text-gray-500">
                    {{ $t('label.widget.modal.department.check_options_label') }}
                </label>

            </div>

            <FormInput 
                name="title"
                :placeholder="$t('placeholder.widget.modal.department.name')" 
                :headerLabel="$t('label.widget.modal.common.name')"
                :footerLabel="$t('label.widget.modal.department.name_label')"
                :errors="errors.title"
                :requiredField="false"
                :modelValue="department.mainSettings.title"
                @update:modelValue="updateDepartment({ value: $event, name: 'title', resource: 'mainSettings' })"
            />

            <FormInput 
                name="email"
                :requiredField="false"
                :headerLabel="$t('label.widget.modal.department.email')"
                :footerLabel="$t('label.widget.modal.department.contact_email_label')"
                :placeholder="$t('placeholder.widget.modal.department.email')" 
                :errors="errors.email"
                :modelValue="department.mainSettings.email"
                @update:modelValue="updateDepartment({ value: $event, name: 'email', resource: 'mainSettings' })"
            />

            <FormInput 
                name="phoneNumber"
                :requiredField="false"
                :headerLabel="$t('label.widget.modal.department.phone_number')"
                :footerLabel="$t('label.widget.modal.department.phone_number_label')"
                :placeholder="$t('label.widget.modal.department.phone_number')"
                :errors="errors.phoneNumber"
                :modelValue="department.mainSettings.phoneNumber"
                @update:modelValue="updateDepartment({ value: $event, name: 'phoneNumber', resource: 'mainSettings' })"
            />

        </form>

    </section>

</template>

<script setup>

/* eslint-disable */
import { computed } from 'vue'
import useThemeToggler from '@/composables/useThemeToggler'
import validator from '@/composables/auth/validator'
import { useStore } from 'vuex'


const props = defineProps({
    department: {
        type: [Object, Array],
        required: true
    },

    subscriptionPlans: {
        type: [Array],
        required: true,
    }
})

const store = useStore();
const widget = computed(() => store.getters['widget/activeWidget']);
const planId = widget?.value?.plan;
const plan = computed(()=>props.subscriptionPlans.find(i => i.id === planId));

const checkInput = (value) => {
    return !plan.value.features[value]
}

const emit = defineEmits(['update:department'])

const { errors, removeError } = validator()

const { isDarkthemeMode } = useThemeToggler()


const updateDepartment = (data) => {
    const { value, name, resource = 'contactOptions' } = data
    
    const editingDepartment = props.department

    if(resource == 'mainSettings') editingDepartment.mainSettings[name] = value
    else editingDepartment.mainSettings.contactOptions[name] = value
    
    emit('update:department', editingDepartment)

}


</script>
