<template>
    <aside class="bg-app-purple h-full w-full col-span-1 border-r-2 border-gray-500 py-3">
        <ul class="relative h-full ml-3">
            <!-- <li class="mb-5">
                <RouterLink :to="{ name: 'Home' }" :title="$t('link.app.common.home')">
                    <Icon name="home" class="text-gray-500"/> 
                </RouterLink>
            </li> -->
            <ListItem
                :title="$t('link.app.common.home')"
                @toggle="$router.push({ name: 'Home' })"
                :icon="'home'"
                :isActive="route.name == 'Home'"
            />
            <!-- <li class="mb-5">
                <RouterLink :to="{ name: 'contacts' }" :title="$t('link.app.common.settings')">
                    <Icon name="settings" class="text-gray-500"  />
                </RouterLink>
            </li> -->
            <ListItem
                :title="$t('link.app.common.settings')"
                @toggle="$router.push({ name: 'contacts' })"
                :icon="'settings'"
                :isActive="
                route.name == 'contacts' || 
                route.name == 'contact-departments' ||
                route.name == 'sidebar' ||
                route.name == 'appearance' ||
                route.name == 'lang' ||
                route.name == 'installation' ||
                route.name == 'security' ||
                route.name == 'security-country' ||
                route.name == 'security-blacklist' 
                "
            />
            <!-- <li class="mb-5">
                <RouterLink :to="{ name: 'reports' }" :title="$t('link.app.common.reports')">
                    <Icon name="chart-bar" class="text-gray-500" /> 
                </RouterLink>
            </li> -->
            <ListItem
                :title="$t('link.app.common.reports')"
                @toggle="$router.push({ name: 'reports' })"
                :icon="'chart-bar'"
                :isActive="
                route.name == 'reports' ||
                route.name == 'report-location' ||
                route.name == 'report-stats'
                "
            />
            <li class="mb-5">
                <RouterLink :to="{ name: 'tariffs' }" :title="$t('link.app.common.tariffs')">
                    <Icon name="user" class="text-gray-500" :isActive="route.name == 'tariffs'"/> 
                </RouterLink>
            </li>
            <!-- <ListItem
                :title="$t('link.app.common.tariffs')"
                @toggle="$router.push({ name: 'tariffs' })"
                :icon="'user'"
                :isActive="route.name == 'tariffs'"
            /> -->
            <li class="w-8 absolute top-full left-1/2 z-10" style="transform: translate(-50%, -100%)">
                <!-- <RouterLink :to="{ name: 'Home' }"> -->
                <a href="/">    
                    <img src="@/assets/images/header-logo.png" alt="Xlinx" class="w-full h-full">
                <!-- </RouterLink> -->
                </a>
            </li>
        </ul>
    </aside>
</template>

<script setup>
/* eslint-disable */ 
import ListItem from './ListItem'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
</script>