<template>
  <form
    @input="removeError($event.target.name)"
    @change="removeError($event.target.name)"
  >
    <FormInput
      name="name"
      :headerLabel="$t('label.widget.modal.common.name')"
      :placeholder="$t('heading.widget.modal.phone_modal.phone_name')"
      :footerLabel="$t('label.widget.modal.common.name_label')"
      :errors="errors.name"
      v-model="phone.name"
    />

    <!-- <SelectKeyValueWithSearch
            name="payment_method"
            id="payment_method"
            :options="paymentOptions" 
            :headerLabel="$t('label.widget.modal.phone_modal.payment_method')"
            :errors="errors.payment_method"
            v-model="phone.payment_method"
            :modelValue="phone.payment_method"
            class="my-5"
        /> -->

    <SelectKeyValueWithSearch
      name="plan_category"
      id="plan_category"
      :options="formattedCategories"
      :headerLabel="$t('label.widget.modal.phone_modal.plan')"
      :errors="errors.plan_category"
      v-model="phone.plan_category"
      class="my-5"
      @update:modelValue="getCategoryPlanNames($event)"
    />

    <SelectKeyValueWithSearch
      name="plan"
      id="plan"
      :options="categoryPlanNames"
      :headerLabel="$t('label.widget.modal.phone_modal.plan-name')"
      :errors="errors.plan"
      v-model="phone.plan"
      class="my-5"
      @update:modelValue="getBillingPeriods($event, phone.plan_category)"
    />

    <SelectKeyValueWithSearch
      name="billing_period"
      id="billing_period"
      :options="billingPeriods"
      :headerLabel="$t('label.widget.modal.phone_modal.billing-period')"
      :errors="errors.billing_period"
      v-model="phone.billing_period"
      class="my-5"
    />

    <SelectKeyValueWithSearch
      name="active"
      id="active"
      :options="options"
      :headerLabel="$t('label.widget.modal.phone_modal.availability')"
      :errors="errors.active"
      v-model="phone.is_active"
    />

    <FormInput
      name="gdpr"
      headerLabel="GDPR"
      :placeholder="$t('label.widget.modal.phone_modal.gdpr')"
      :footerLabel="$t('label.widget.modal.phone_modal.gdpr')"
      :errors="errors.gdpr"
      v-model="config.gdpr"
    />

    <FormInput
      name="terms"
      headerLabel="Terms"
      :placeholder="$t('label.widget.modal.phone_modal.terms')"
      :footerLabel="$t('label.widget.modal.phone_modal.terms')"
      :errors="errors.terms"
      v-model="config.terms"
    />
  </form>
</template>

<script setup>
import { inject, onMounted, computed } from "vue";
import { upperFirst } from "lodash";
import store from "@/store/index";
import validator from "@/composables/auth/validator";
import usePhone from "@/modules/widget/composables/usePhone";

const { errors, removeError, clearErrors } = validator();

const {
  options,
  planNames,
  billingPeriods,
  getBillingPeriods,
  categoryPlanNames,
  formattedCategories,
  getCategoryPlanNames,
  getSubscriptionPlans,
} = usePhone();

const phone = inject("phone");
const config = inject("config");

const defaults = [
  {
    value: 0,
    name: "Please select",
  },
];

const paymentOptions = computed(() => {
  const payment_methods = store.state.profilestore.user_payments_methods;
  if (payment_methods != undefined && payment_methods.length) {
    const methods = payment_methods.map((method) => {
      return {
        value: method.id,
        name:
          upperFirst(method.card_type?.replace("_", " ")) +
          " (" +
          method.last_four +
          " )",
      };
    });
    return [...defaults, ...methods];
  }
  return defaults;
});

onMounted(() => {
  clearErrors();
});
</script>

<style lang="scss" scoped></style>
