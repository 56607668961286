<template>
  <div
    class="sidebar text-black dark:text-off-white grid grid-cols-9 border-0 border-r border-gray-300 dark:bg-black full-screen-width h-screen"
  >
    <aside-section />

    <section class="col-span-8">
      <Header />

      <div class="main text-left">
        <ul class="font-inter">
          <template v-if="flow1">
            <template v-if="hasAnyPhoneItem">
              <ListItem
                :title="$t('link.widget.sidebar.contacts')"
                mainClasses="flex items-center"
                classes="inline-block flex-grow"
                icon="contact"
                iconClass="-mt-1"
                @toggle="toggleContacts"
                :isActive="isContactPage"
              >
                <template #meta>
                  <span
                    v-show="isContactPage"
                    class="bg-grayish text-sm w-8 h-8 mr-1.5 cursor-pointer dark:bg-gray-700 flex justify-center items-center font-bold font-body"
                    @click.prevent="toggleModal('addOffice', true)"
                    :title="$t('text.app.common.add-new', { name: 'Contact' })"
                  >
                    <Icon name="plus" height="14" width="14" />
                  </span>
                </template>
              </ListItem>

              <contacts-section v-show="isContactPage" />

              <ListItem
                :title="$t('link.widget.sidebar.sidebar')"
                icon="sidebar"
                @toggle="togglePage('sidebar')"
                :isActive="route.name == 'sidebar'"
              />

              <sidebar-section v-show="route.name == 'sidebar'" />

              <ListItem
                :title="$t('link.widget.sidebar.appearance')"
                icon="appearance"
                @toggle="togglePage('appearance')"
                :isActive="route.name == 'appearance'"
              />

              <appearance-section v-show="route.name == 'appearance'" />

              <ListItem
                :title="$t('link.widget.sidebar.security')"
                icon="security"
                @toggle="togglePage('security')"
                :isActive="route.name == 'security'"
              />

              <security-section
                v-show="
                  [
                    'security',
                    'security-country',
                    'security-blacklist'
                  ].includes(route.name)
                "
              />

              <ListItem
                :title="$t('link.widget.sidebar.multilanguage')"
                icon="multilanguage"
                @toggle="togglePage('lang')"
                :isActive="route.name == 'lang'"
              />

              <multilanguage-section v-show="route.name == 'lang'" />

              <ListItem
                :title="$t('link.widget.sidebar.installation')"
                icon="installation"
                @toggle="togglePage('installation')"
                :isActive="route.name == 'installation'"
              />

              <installation-section v-show="route.name == 'installation'" />
            </template>
          </template>

          <template v-else-if="flow2">
            <ListItem
              :title="$t('link.widget.sidebar.locations_map')"
              icon="location-map"
              :isActive="route.name == 'report-location'"
              @toggle="$router.push({ name: 'report-location' })"
            />

            <ListItem
              :title="$t('link.widget.sidebar.reports')"
              icon="stats"
              :isActive="route.name == 'report-stats'"
              @toggle="$router.push({ name: 'report-stats' })"
            />

            <report-list />
          </template>

          <template v-else-if="flow3">
            <ListItem
              :title="$t('link.main.pages.tariffs.prices_tariffs')"
              icon="tariffs"
              :isActive="['tariffs', 'tariffs-type'].includes(route.name)"
              @toggle="$router.push({ name: 'tariffs' })"
            />

            <tariff-types />
          </template>
        </ul>
      </div>
    </section>

    <edit-office v-if="isOpenModal('editOffice')" />
    <add-office v-if="isOpenModal('addOffice')" />

    <add-phone v-if="isOpenModal('addPhone')" />
    <edit-phone v-if="isOpenModal('editPhone')" />
  </div>
</template>

<script>
export default {
  name: 'sidebar'
}
</script>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import useModal from '@/composables/useModal'
import useActiveToggler from '@/composables/useActiveToggler'
import useThemeToggler from '@/composables/useThemeToggler'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

import Header from './Header'
import ListItem from './ListItem'
import asideSection from '../aside-section'
import contactsSection from '../contacts-section/index.vue'
import sidebarSection from '../sidebar-section.vue'
import appearanceSection from '../appearance-section/index.vue'
import multilanguageSection from '../multilanguage-section.vue'
import installationSection from '../installation-section.vue'
import securitySection from '../security-section.vue'
import reportList from '../Reports/report-list'
import TariffTypes from '../../pricesAndTariffs/TariffTypes.vue'

import editOffice from '../modals/edit-office'
import addOffice from '../modals/add-office'
import addPhone from '../modals/add-phone'
import editPhone from '../modals/edit-phone'

const { toggleConfig, isActiveConfig, isActiveDropdown, toggleDropdown } =
  useActiveToggler()
const { isDarkthemeMode } = useThemeToggler()
const { toggleModal, isOpenModal } = useModal()

const route = useRoute()
const router = useRouter()
const store = useStore()

const flow1 = computed(() => {
  return [
    'Home',
    'contacts',
    'contact-departments',
    'sidebar',
    'appearance',
    'lang',
    'installation',
    'security',
    'security-country',
    'security-blacklist'
  ].includes(route.name)
})

const flow2 = computed(() => {
  return [
    'reports',
    'calls',
    'callRequests',
    'videoCalls',
    'emails',
    'messages',
    'report-stats',
    'report-location'
  ].includes(route.name)
})

const flow3 = computed(() => ['tariffs', 'tariffs-type'].includes(route.name))

const isContactPage = computed(() => {
  return ['contacts', 'contact-departments'].includes(route.name)
})

const hasAnyPhoneItem = computed(() => {
  return (
    store.getters['widget/widgets'].length &&
    store.getters['widget/activeWidgetId'] != -1
  )
})

const toggleContacts = () => {
  if (!isContactPage.value) router.push({ name: 'contacts' })
  else router.push({ name: 'Home' })
}

const togglePage = page => {
  if (route.name == page) router.push({ name: 'Home' })
  else router.push({ name: page })
}
</script>

<style scoped lang="scss">
.edit-phone-icon {
  visibility: hidden;
}
.dropdown-item:hover .edit-phone-icon {
  visibility: visible;
}
</style>
