<template>
    
    <div class="p-4 py-6" v-if="appearance">
        
        <ColorSchemes />

        <div class="my-4 pr-5 grid grid-cols-12">
            
            <label for="textLabel" class="col-span-5">{{ $t('link.widget.sidebar.appearance.position') }}</label>
            <div class="form-group flex w-full justify-between col-span-7 ml-4">
                <div>
                    <input 
                        type="radio" 
                        name="widget-position" 
                        value="left" 
                        id="widgetLeftPosition" 
                        v-model="appearance.widgetPosition"
                        @change="saveThrottledAppearance"
                    />
                    <label for="widgetLeftPosition" class="dark:text-off-white">
                        {{ $t('text.app.common.left') }}
                        <span class="radio-button dark:bg-gray-800"></span>
                    </label>
                </div>
                <div>
                    <input 
                        type="radio" 
                        name="widget-position" 
                        value="right" 
                        id="widgetRightPosition" 
                        v-model="appearance.widgetPosition"
                        @change="saveThrottledAppearance"
                    />
                    <label for="widgetRightPosition" class="dark:text-off-white">
                        {{ $t('text.app.common.right') }}
                        <span class="radio-button dark:bg-gray-800"></span>
                    </label>
                </div>
            </div>
            
        </div>

    </div>

</template>

<script setup>

import lodash from 'lodash'
import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import ColorSchemes from './ColorSchemes'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

const store = useStore()
const appearance = computed(() => store.getters['widget/activeWidget'].appearance)

const { saveThrottledAppearance, resetColorScheme } = useWidgetConfig()

watch(appearance, (value) => {
    if(lodash.isEmpty(value)) {
        const input = document.querySelector(`input[name="widget-position"][value="${value}"]`)
        if(input) input.checked = true
    }
})


</script>