<template>
  <header
    class="bg-app-purple text-white py-3 pl-4 pr-2 text-sm text-left flex justify-between items-center cursor-pointer"
    @click.prevent="toggleDropdown('editPhone')"
  >
    <div class="text-large leading-6">
      {{ activePhone?.name || $t("label.main.pages.home.header.phone_name") }}
    </div>

    <div class="flex justify-center items-center">
      <div class="relative mr-2">
        <Icon name="angle-down" class="cursor-pointer" />

        <dropdown
          id="editPhone"
          classes="top-6 dropdown-edit-phone"
          style="right: -3.8rem; width: 27.6rem !important"
          v-if="isActiveDropdown('editPhone')"
        >
          <template v-if="loading.value && loading.id == 'widget'">
            <ListLoader :count="3" class="p-3 pr-5" />
          </template>

          <template v-else>
            <template v-if="phoneItems.length">
              <dropdown-item
                classes="dropdown-item"
                :id="item.id"
                v-for="item in phoneItems"
                :key="item.id"
                class="relative pen"
                @click.stop="switchPhone(item)"
              >
                <span class="inline-block mr-1">{{ item.name }}</span>
                <span
                  class="icon inline-block absolute right-1 edit-phone-icon text-dark-green font-inter text-base font-medium"
                >
                  <div class="flex icon-invisible">
                    <Icon
                      name="edit"
                      class="mr-1"
                      title="Edit"
                      @click.stop="toggleEdit(item)"
                    />

                    <Icon
                      name="times"
                      class="mx-1"
                      title="Delete"
                      @click.stop="deletePhone(item)"
                    />
                  </div>
                </span>
              </dropdown-item>
            </template>

            <template v-else>
              <dropdown-item classes="dropdown-item" id="phone-dropdown">
                {{ $t("validation.modules.components.header.no_phone") }}.
              </dropdown-item>
            </template>
          </template>
        </dropdown>
      </div>

      <span
        class="bg-app-purple-transparent text-sm w-8 h-8 mr-1.5 cursor-pointer dark:bg-gray-700 ml-2 flex justify-center items-center"
        @click.stop="openPhoneModal"
        :title="$t('text.app.common.add-new', { name: 'Phone' })"
      >
        <Icon name="plus" class="" color="#ffffff" />
      </span>
    </div>
  </header>
</template>

<script setup>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import ListLoader from "../ListLoader.vue";
import useModal from "@/composables/useModal";
import useActiveToggler from "@/composables/useActiveToggler";
import usePhone from "@/modules/widget/composables/usePhone";

const store = useStore();
const t = inject("t");
const { toggleModal } = useModal();
const { toggleDropdown, isActiveDropdown } = useActiveToggler();
const { setActivePhone, deletePhoneData } = usePhone();

const phoneItems = computed(() => store.getters["widget/widgets"]);
const activePhone = computed(() => store.getters["widget/activeWidget"]);
const loading = computed(() => store.getters["widget/loading"]);

const toggleEdit = async (phone) => {
  setActivePhone(phone);
  toggleDropdown("editPhone");
  toggleModal("editPhone", true);
};

const switchPhone = (phone) => {
  toggleDropdown("editPhone");
  setActivePhone(phone);
};

const openPhoneModal = () => {
  if (isActiveDropdown("editPhone")) toggleDropdown("editPhone");
  toggleModal("addPhone", true);
};

const deletePhone = async (phone) => {
  const result = await Swal.fire({
    title: t("notify.modules.components.common.prompt_delete"),
    text:
      t("notify.modules.components.header.prompt_delete_confirmation") + "!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#1AB394",
    cancelButtonColor: "#d33",
    confirmButtonText:
      t("notify.modules.components.common.delete_confirmation") + "!",
  });

  if (result.isConfirmed) {
    await deletePhoneData(phone);
  }
};
</script>

<style lang="scss" scoped>
.icon-invisible {
  visibility: hidden;
  opacity: 0;
}

.pen:hover .icon-invisible {
  visibility: visible;
  opacity: 1;
}
</style>
