<template>
    <div>

        <template v-if="loading.value && loading.id == 'widget'">
            <ListLoader :count="3" class="p-3" />
        </template>
        
        <template v-else>
            
            <ul class="p-3" v-if="widget && widget.contacts?.length">

                <template 
                v-for="contact in widget.contacts" 
                :key="contact.id">
                        
                    <li class="flex items-center">
                        <a href="#" 
                        class="hover:text-black dark:text-off-white block flex-grow text-lg"
                        :class="[isActiveDepartment(contact.id) ? ['text-black', 'font-medium'] : 'text-light-gray']"
                        @click.prevent="$router.push({ name: 'contact-departments', params: { id: contact.id } })">
                            {{ contact.name }}
                        </a>

                        <contact-buttons :contact="contact" />

                    </li>

                    <departments 
                        v-show="isActiveDepartment(contact.id)"
                        :departments="contact.departments"
                        :contactId="contact.id"
                    />

                </template>

                
            </ul>

            <ul v-else class="p-3">
                <li>{{ $t('validation.modules.components.contacts-section.no_contacts') }}.</li>
            </ul>

        </template>

        <add-department v-if="isOpenModal('addDepartment')" />
        <edit-department v-if="isOpenModal('editDepartment')" />

    </div>

</template>

<script setup>

import { provide, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import useActiveToggler from '@/composables/useActiveToggler'
import useModal from '@/composables/useModal'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

import editDepartment from '../modals/edit-department'
import addDepartment from '../modals/add-department'

import departments from './departments.vue'
import contactButtons from './contact-config-buttons'
import ListLoader from '../ListLoader'

const { toggleConfig, isActiveConfig, activeDropdown, isActiveDropdown, toggleDropdown } = useActiveToggler()
const { toggleModal, isOpenModal } = useModal()
const { config, activeContact } = useWidgetConfig()
const route = useRoute()
const store = useStore()

const widget = computed(() => store.getters['widget/activeWidget'])
const widgetLength = computed(() => store.getters['widget/widgets'].length)
const loading = computed(() => store.getters['widget/loading'])

const toggleModalAndActivateContact = (id) => {
    activeContact.value = id
    toggleModal('addDepartment', true)
}

const isActiveDepartment = (id) => {
    return id == route.params.id && route.name == 'contact-departments'
}

provide('isCurrentConfig', isActiveConfig)


</script>