<template>

    <ul class="tabs grid grid-cols-2 m-0 p-0 dark:text-off-white text-large leading-6">
        <li 
        class="justify-self-center border-0 border-b-2 w-full" 
        :class="[config == 'mainSettings' ? 'border-dark-green' : 'border-dark-gray opacity-50']" 
        @click.prevent="toggleConfig('mainSettings')">
            <a href="#">Main settings</a>
        </li>

        <li 
        class="justify-self-center border-0 border-b-2 w-full"
        :class="[config == 'openingHours' ? 'border-dark-green' : 'border-dark-gray opacity-50']" 
        @click.prevent="toggleConfig('openingHours')"> 
            <a href="#">Opening Hours</a>
        </li>

    </ul>

</template>

<script>
/* eslint-disable */

export default {

    props: {
        config: {
            default: 'mainSettings',
            type: String
        }
    },

    emits: ['toggle'],

    setup(props, { emit }) {
        const toggleConfig = (id) => {
            emit('toggle', id)
        }
        return {
            toggleConfig,
        }
    } 

}
</script>