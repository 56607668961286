<template>
  <div class="installation-section w-full overflow-hidden px-4 py-6">
    <div class="my-3 cursor-pointer">
      <img
        @click="handleType(2)"
        src="@/assets/images/wordpress-logo-light.svg"
        alt="Wordpress Logo"
        class="inline-block align-middle w-10 h-12"
      />
      <img
        @click="handleType(1)"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/480px-Unofficial_JavaScript_logo_2.svg.png"
        alt="JS Logo"
        class="w-8 h-8 inline-block align-middle"
      />
      <!-- <span class="inline-block align-middle ml-1">Wordpress</span> -->
    </div>

    <div>
      <BaseButton
        v-if="installType === 2"
        @click.prevent="installCode"
        classes="py-2.5 px-6 rounded shadow hover:opacity-95"
        style="margin-left: 0"
        :text="$t('link.widget.sidebar.installtion.install')"
      >
        <font-awesome-icon icon="check" />
      </BaseButton>
    </div>

    <!-- <div class="my-3 cursor-pointer" @click="handleType(1)">
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/480px-Unofficial_JavaScript_logo_2.svg.png" alt="JS Logo" class="w-8 h-8 inline-block align-middle">
            <span class="inline-block ml-3 align-middle">JavaScript</span> 
        </div>  -->

    <div class="mt-4 form-group" v-if="installType === 1">
      <label class="text-sm">{{ $t('link.widget.sidebar.place_code') }}</label>

      <div
        name="code-section"
        id="codeSection"
        class="border border-dark-green rounded w-full text-sm px-1 py-2 my-1 dark:bg-gray-800 dark:text-off-white"
        v-text="widgetLink"
      ></div>

      <BaseButton
        :text="copiedText"
        @click.prevent="copyCode"
        classes="py-2.5 px-6 rounded shadow hover:opacity-95"
        style="margin-left: 0"
        :title="buttonTitle"
      >
        <font-awesome-icon icon="check" />
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, inject, computed } from 'vue'
import useWidgetConfig from '@/modules/widget/composables/useWidgetConfig'

const t = inject('t')
const notify = inject('notify')
const store = useStore()

const widget = computed(() => store.getters['widget/activeWidget'])

const widgetLink = computed(() => {
  return (
    '<script' +
    ` src="https://widget.xlinx.eu/widget.js?client=${widget.value.widget_id}" data-name="client-widget"></` +
    'script>'
  )
})

const copied = ref(false)
const installType = ref(1)
const copiedText = computed(() => {
  return copied.value
    ? t('link.widget.sidebar.installtion.copied')
    : t('link.widget.sidebar.installtion.copy')
})

const buttonTitle = computed(() => {
  return copied.value
    ? t('link.widget.sidebar.installtion.copied-title')
    : t('link.widget.sidebar.installtion.copy-title')
})

const copyCode = () => {
  const elem = document.querySelector('#codeSection')
  if (!navigator.clipboard) {
    elem.select()
    copied.value = true
    return
  }
  navigator.clipboard.writeText(widgetLink.value)
  copied.value = true
}

const handleType = val => {
  installType.value = val
}

const installCode = () => {
  const token = localStorage.getItem('pluginToken')
  const url = localStorage.getItem('pluginUrl')

  if (!token || !url) {
    notify({
      message: t('validation.modules.widget.validate.not_exists_token_url'),
      type: 'success'
    })
  } else {
    window.location.href = `${url}&token=${token}&client=${widget.value.widget_id}`
  }
  // const url = window.location != window.parent.location ? document.referrer : document.location.href;
  // store.dispatch('widget/installPhone', {url, data: { input_string: `https://widget.xlinx.eu/widget.js?client=${widget.value.widget_id}` } });
}
</script>
