import store from "@/store";
import { GET_TARIFFS, GET_TARIFF_TYPES } from "../store/types";

export default function useTariffs() {
  const getTariffs = async (type) => {
    await store.dispatch(`tariffs/${GET_TARIFFS}`, type);
    return Promise.resolve();
  };

  const getTariffTypes = async () => {
    await store.dispatch(`tariffs/${GET_TARIFF_TYPES}`);
  };

  return {
    getTariffs,
    getTariffTypes,
  };
}
