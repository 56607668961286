<template>
  <modal
    @toggle="toggleModal('editDepartment')"
    :open="isOpenModal('editDepartment')"
  >
    <template #header>Edit Department</template>
    <template #content>
      <department-tabs
        :config="activeConfig.id"
        @toggle="toggleConfig($event)"
      />
      <main-settings-edit
        v-show="!isActiveConfig('openingHours')"
        v-model:department="department"
        :subscriptionPlans="subscriptionPlans"
      />
      <opening-hours-edit
        v-show="isActiveConfig('openingHours')"
        v-model:department="department"
      />
    </template>

    <template #footer>
      <BaseButton
        kind="default"
        :text="$t('button.label.cancel')"
        @click.prevent="toggleModal"
        :title="$t('button.label.cancel')"
      />

      <BaseButton
        :text="$t('button.label.save-changes')"
        @click.prevent="edit"
        :title="$t('button.label.save-changes')"
        :disabled="loading"
      >
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </BaseButton>
    </template>
  </modal>
</template>

<script setup>
import { cloneDeep, isEmpty } from "lodash";

import { onMounted, ref, inject, computed } from "vue";
import { useStore } from "vuex";
import { isValidPhoneNumber, isPossibleNumber } from "libphonenumber-js";

import modal from "@/shared/modal";
import openingHoursEdit from "../contacts-section/opening-hours-edit";
import departmentTabs from "../contacts-section/department-tabs";
import mainSettingsEdit from "../contacts-section/department-main-settings-edit.vue";

import useModal from "@/composables/useModal";
import validator from "@/composables/auth/validator";
import useActiveToggler from "@/composables/useActiveToggler";
import useWidgetConfig from "../../../composables/useWidgetConfig";
import { validateDepartment } from "@/composables/auth/widgetValidations";

const store = useStore();
const t = inject("t");
const notify = inject("notify");
const { clearErrors, errors, setErrors } = validator();
const { toggleModal, isOpenModal } = useModal();
const { editDepartment, reset, fixErrorsAlert, loading } = useWidgetConfig();
const { toggleConfig, isActiveConfig, activeConfig } = useActiveToggler();
const subscriptionPlans = computed(() => store.getters["subscriptionPlans"]);
const widget = computed(() => store.getters["widget/activeWidget"]);
const planId = widget?.value?.plan;
const department = ref(cloneDeep(store.getters["widget/department"]));
const plan = computed(() =>
  subscriptionPlans?.value.find((i) => i.id === planId)
);

const edit = async () => {
  if (!validateDepartment(department)) {
    fixErrorsAlert();
    return;
  }

  let phoneNumber = department.value.mainSettings.phoneNumber;
  if (plan?.value?.features?.pbx) {
    if (!(phoneNumber?.length >= 2 && phoneNumber?.length <= 5))
      if (!phoneNumber.includes("+")) {
        notify({
          message: t("valiation.pages.common.phone_number_incorrect"),
          type: "danger",
        });
        setErrors({
          key: "phoneNumber",
          message: t("valiation.pages.common.phone_number_incorrect"),
        });
        return;
      } else if (phoneNumber.includes("+")) {
        if (!isPossibleNumber(phoneNumber)) {
          notify({
            message: t("valiation.pages.common.phone_number_incorrect"),
            type: "danger",
          });
          setErrors({
            key: "phoneNumber",
            message: t("valiation.pages.common.phone_number_incorrect"),
          });
          return;
        }
      }
  } else {
    if (phoneNumber?.length >= 2 && phoneNumber?.length <= 5) {
      notify({
        message: t("valiation.pages.common.phone_number_incorrect"),
        type: "danger",
      });
      setErrors({
        key: "phoneNumber",
        message: t("valiation.pages.common.phone_number_incorrect"),
      });
      return;
    }
    if (!isPossibleNumber(phoneNumber)) {
      notify({
        message: t("valiation.pages.common.phone_number_incorrect"),
        type: "danger",
      });
      setErrors({
        key: "phoneNumber",
        message: t("valiation.pages.common.phone_number_incorrect"),
      });
      return;
    }
  }

  await editDepartment(department);
  if (isEmpty(errors.value)) {
    toggleModal("editDepartment");
    reset();
  }
};

onMounted(() => {
  clearErrors();
  toggleConfig("mainSettings");
});
</script>
