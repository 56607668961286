<template>
  <modal @toggle="toggleModal('addPhone')" :open="isOpenModal('addPhone')">
    <template #header>
      {{ $t("heading.widget.modal.phone_modal.add_phone") }}
    </template>

    <template #content>
      <PhoneForm />
    </template>

    <template #footer>
      <BaseButton
        @click.prevent="add"
        :text="$t('button.label.save-changes')"
        :title="$t('button.label.save-changes')"
        :disabled="loading"
      >
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </BaseButton>
    </template>
  </modal>
</template>

<script setup>
import { isEmpty } from "lodash";
import { useStore } from "vuex";
import { watch, reactive, onMounted, provide, computed } from "vue";
import PhoneForm from "./PhoneForm";
import modal from "@/shared/modal";
import useModal from "@/composables/useModal";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";
import usePhone from "@/modules/widget/composables/usePhone";
import validator from "@/composables/auth/validator";

const phone = reactive({
  plan_category: 0,
  plan: 0,
  name: "",
  is_active: true,
  billing_period: 0,
  payment_method: 0,
});

const config = reactive({
  gdpr: null,
  terms: null,
});

const store = useStore();

const { toggleModal, isOpenModal } = useModal();
const { fixErrorsAlert } = useWidgetConfig();
const { errors } = validator();

const {
  loading,
  addPhone,
  resetPhone,
  validatePhone,
  getSubscriptionPlans,
  setActivePhone,
} = usePhone();

const phoneItems = store.getters["widget/widgets"];
const phoneItemsLength = phoneItems.length;
const hasAnyPhoneItem = phoneItemsLength ? true : false;

const add = async () => {
  if (!validatePhone(phone, config)) {
    fixErrorsAlert();
    return;
  }

  await addPhone(phone, config);

  if (isEmpty(errors.value)) {
    if (!hasAnyPhoneItem) setActivePhone(phoneItems[0]);
    toggleModal("addPhone");
    resetPhone();
  }
};

provide("phone", phone);
provide("config", config);

onMounted(() => getSubscriptionPlans());
</script>
