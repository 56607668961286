<template>
  <section id="mainSettings">
    <form
      @input="removeError($event.target.name)"
      @change="removeError($event.target.name)"
    >
      <div class="text-large leading-6 font-inter mb-6 mt-3">
        <label for="introduction" class="font-bold">
          {{ $t("label.widget.modal.department.select_contact") }}
        </label>

        <div class="grid grid-cols-2 mt-3">
          <FormCheck
            name="phone"
            :label="$t('label.widget.modal.department.phone_calls')"
            v-model="department.mainSettings.contactOptions.phoneCalls"
          />

          <FormCheck
            name="requestCallback"
            :label="$t('label.widget.modal.department.request_callback')"
            v-model="department.mainSettings.contactOptions.requestCallback"
          />

          <FormCheck
            name="chat"
            :label="$t('label.widget.modal.department.chat')"
            v-model="department.mainSettings.contactOptions.chat"
          />

          <FormCheck
            name="email"
            :label="$t('label.widget.modal.department.email')"
            v-model="department.mainSettings.contactOptions.email"
          />

          <FormCheck
            name="videoCalls"
            :label="$t('label.widget.modal.department.video_calls')"
            v-model="department.mainSettings.contactOptions.videoCalls"
          />
        </div>

        <label for="name" class="text-xs text-gray-500">
          {{ $t("label.widget.modal.department.check_options_label") }}
        </label>
      </div>

      <FormInput
        name="title"
        :placeholder="$t('placeholder.widget.modal.department.name')"
        :headerLabel="$t('label.widget.modal.common.name')"
        :footerLabel="$t('label.widget.modal.department.name_label')"
        v-model="department.mainSettings.title"
        :errors="errors.title"
        :requiredField="false"
      />

      <FormInput
        name="email"
        :requiredField="false"
        :headerLabel="$t('label.widget.modal.department.email')"
        :footerLabel="$t('label.widget.modal.department.contact_email_label')"
        :placeholder="$t('placeholder.widget.modal.department.email')"
        v-model="department.mainSettings.email"
        :errors="errors.email"
      />

      <FormInput
        name="phoneNumber"
        :requiredField="false"
        :headerLabel="$t('label.widget.modal.department.phone_number')"
        :footerLabel="$t('label.widget.modal.department.phone_number_label')"
        :placeholder="$t('label.widget.modal.department.phone_number')"
        v-model="department.mainSettings.phoneNumber"
        :errors="errors.phoneNumber"
        number
        maxlength="20"
      />
    </form>
  </section>
</template>

<script setup>
import validator from "@/composables/auth/validator";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";

const { errors, removeError } = validator();
const { department } = useWidgetConfig();
</script>
