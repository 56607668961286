<template>
    <div class="installation-section w-full overflow-hidden px-5 py-1">
       <ul class="sub-menu flex flex-col justify-start items-start w-full p-3 pr-5">
           <li class="w-full text-xl hover:text-gray-500 px-2 py-1 sub_item_list cursor-default" @click.prevent="selectCountryItem()" :class="{'active': itemActive[0]}">Countries</li>
           <li class="w-full text-xl hover:text-gray-500 px-2 py-1 sub_item_list cursor-default" @click.prevent="selectBlacklistItem()" :class="{'active': itemActive[1]}">Blacklist</li>
       </ul>
    </div>
</template>

<script setup>
    import { useRouter, useRoute } from 'vue-router'
    import { onMounted, ref } from "vue";

    const router = useRouter()
    const route = useRoute()
    const itemActive = ref([]);

    const selectCountryItem = () => {
        router.push({ name: 'security-country', params:{visibleSubMenu : true}});
    }
    const selectBlacklistItem = () => {
        router.push({ name: 'security-blacklist',params:{visibleSubMenu : true}});
    }

    const isActiveSubMenu = () => {
        if(route.name == 'security-country'){
            itemActive.value[0] = true;
        }else if(route.name == 'security-blacklist'){
            itemActive.value[1] = true;
        }else{
            itemActive.value[0] = false;
            itemActive.value[1] = false;
        }

    }
    onMounted(() => {
        isActiveSubMenu()
    });
</script>

<style scoped>

    .sub-menu li.active {
        font-weight: 500;
    }

</style>
