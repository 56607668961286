<template>
  <modal @toggle="toggleModal('editOffice')" :open="isOpenModal('editOffice')">
    <template #content>
      <form
        @input="removeError($event.target.name)"
        @change="removeError($event.target.name)"
      >
        <FormInput
          name="name"
          :headerLabel="$t('label.widget.modal.common.name')"
          :placeholder="$t('label.widget.modal.common.name_label')"
          v-model="contact.name"
          :errors="errors.name"
        />

        <Timezones v-model="contact.timezone" />

        <FormInput
          name="address"
          :headerLabel="$t('heading.widget.modal.office.modal.address')"
          :footerLabel="$t('heading.widget.modal.office.modal.country_state')"
          :placeholder="$t('label.widget.modal.common.address')"
          v-model="contact.address"
          :errors="errors.address"
        />

        <FormInput
          name="street_address"
          :requiredField="false"
          :placeholder="$t('heading.widget.modal.office.modal.street_address')"
          :footerLabel="$t('heading.widget.modal.office.modal.street_address')"
          v-model="contact.streetAddress"
        />

        <FormInput
          name="zipcode"
          :requiredField="false"
          :placeholder="$t('heading.widget.modal.office.modal.zipcode')"
          :footerLabel="$t('heading.widget.modal.office.modal.zipcode')"
          v-model="contact.zipcode"
          number
          restrict
          maxlength="5"
        />
      </form>
    </template>

    <template #footer>
      <BaseButton
        kind="default"
        :text="$t('button.label.cancel')"
        @click.prevent="toggleModal"
        :title="$t('button.label.cancel')"
      />

      <BaseButton
        :text="$t('button.label.save-changes')"
        @click.prevent="edit"
        :title="$t('button.label.save-changes')"
        :disabled="loading"
      >
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </BaseButton>
    </template>
  </modal>
</template>

<script setup>
import { isEmpty, cloneDeep } from "lodash";
import { ref, inject, onMounted } from "vue";
import { useStore } from "vuex";
import modal from "@/shared/modal";
import Timezones from "@/shared/Timezones";
import useModal from "@/composables/useModal";
import validator from "@/composables/auth/validator";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";
import { validateContact } from "@/composables/auth/widgetValidations";

const { errors, removeError, clearErrors } = validator();
const { toggleModal, isOpenModal } = useModal();
const { editContact, reset, fixErrorsAlert, loading } = useWidgetConfig();

const store = useStore();
const contact = ref(cloneDeep(store.getters["widget/activeContact"]));

const edit = async () => {
  if (!validateContact(contact)) {
    fixErrorsAlert();
    return;
  }

  await editContact(contact);
  if (isEmpty(errors.value)) {
    toggleModal("editOffice");
    reset();
  }
};

onMounted(() => clearErrors());
</script>
